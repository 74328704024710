.topnav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: rgb(241, 217, 217);
  }

  .options {
      float: left;
      font-family:'Oxanium',cursive, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-size: larger;
      color:rgb(48, 9, 25) ;  
      
    }

  .topnav li a {
    display: block;
    color:rgb(48, 9, 25);
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    
  }

  .about {
    font-family:'Oxanium',cursive,Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color:rgb(48, 9, 25) ;  
    font-size: larger;
      float: right;
  }

.topnav li:hover:not(.active) {background-color: rgb(192, 99, 99);}


@media screen and (max-width: 340px) {
    .topnav li.right, 
    .topnav li {float: none;}
    
  } 

  @media screen and (max-width: 1000px) {
    .options {font-size:medium}
    .about {font-size: medium;}
  }