.cardContainer {
    display:flex;
    flex-direction: column;
    width: 500px;
    align-items: center;
    background-color: ivory;
    border-radius: 20px;
}
.privacyPolicy {
    background-color: rgb(123, 187, 230);
    font-family:  'Oxanium', Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    width: 70%;
    border-radius: 15px;
    padding: 10px 0 10px 0;
    margin-bottom: 40px;
    text-align: center;
    font-size: x-large;
    font-weight: bolder;
    text-decoration: none;
}

a {
    text-decoration: none;
    
}

.gameHeader {
    font-family: 'Balsamiq Sans', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    width: 95%;
    border-radius: 15px;
    text-align: center;
    background-color: rgb(253, 136, 136);
    margin: 40px 0px 20px 0px;
    padding: 10px 0 10px 0;
    }

.subText {
    font-family:  'Balsamiq Sans', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    padding-top: 10px ;
    font-size: 23px;
    text-align: center;
}
img {border-radius: 20px;
    width: 90%;
    height: auto;
    border: 3px solid saddlebrown;
}


@media screen and (max-width: 1000px) {
    .cardContainer{ width: 350px;}
  }

@media screen and (max-width: 400px) {
    .cardContainer{ width: 80vw;}
  }


.privacyPolicyMainHeader {
    background-color: sandybrown;
    text-align: center;
    padding: 10px;
 
}
.privacyPolicyDiv {
    width: 800px;
    background-color: ivory;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.privacyPolicyDiv h3 {
    text-align: center;
    font-size: larger;
}

.privacyPolicyDiv h4 {
    padding: 20px;
    margin: 0px;
    font-size: larger;
}

.privacyPolicyDiv a{
    padding: 20px;
    font-size: larger;
    text-decoration-color: blue;
    font-weight: bolder;
    text-decoration: underline;
}

.privacyPolicyDiv p {
    text-align: left;
    margin: 0px;
    padding: 0px 20px 0px 20px;
    font-size: large;
}

.privacyPolicyMainDiv {
    display: flex;
    flex-direction: raw;
    justify-content: center;
}