.RhoeasGamesHeaderDiv {
    margin-top: 20px;
    font-family:'Oxanium',cursive, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    background-color: rgb(241, 217, 217);
      
}

.RhoeasGamesHeaderText {
    margin:1rem; 
    text-align: center; 
    padding: 10px;
}