.RhoeasGamesDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px;
}

@media screen and (max-width: 800px) {
    .RhoeasGamesDiv{ justify-content: space-around;}
  }
