.Rhoeas {
    color: whitesmoke;
    font-size: 100px;
    font-family: 'Oxanium';
}

.RhoeasSubText {
    color: whitesmoke;
    font-size: 50px;
    font-family: 'Concert One';
}

.RhoeasDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 80vh;
}

@media screen and (max-width: 850px) {
    .Rhoeas {font-size: 55px;} 
    .RhoeasDiv {min-height: 60vh;}
    .RhoeasSubText {font-size: 30px;}
    
  }