@import url(https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Carter+One&family=Fredoka+One&family=Oxanium:wght@600&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(48, 9, 25);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavBar_topnav__2NUeH {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: rgb(241, 217, 217);
  }

  .NavBar_options__10Iyn {
      float: left;
      font-family:'Oxanium',cursive, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-size: larger;
      color:rgb(48, 9, 25) ;  
      
    }

  .NavBar_topnav__2NUeH li a {
    display: block;
    color:rgb(48, 9, 25);
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    
  }

  .NavBar_about__3Imb4 {
    font-family:'Oxanium',cursive,Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color:rgb(48, 9, 25) ;  
    font-size: larger;
      float: right;
  }

.NavBar_topnav__2NUeH li:hover:not(.NavBar_active__1Ol27) {background-color: rgb(192, 99, 99);}


@media screen and (max-width: 340px) {
    .NavBar_topnav__2NUeH li.NavBar_right__3vchM, 
    .NavBar_topnav__2NUeH li {float: none;}
    
  } 

  @media screen and (max-width: 1000px) {
    .NavBar_options__10Iyn {font-size:medium}
    .NavBar_about__3Imb4 {font-size: medium;}
  }
.Rhoeas_Rhoeas__2E8UR {
    color: whitesmoke;
    font-size: 100px;
    font-family: 'Oxanium';
}

.Rhoeas_RhoeasSubText__1O1dV {
    color: whitesmoke;
    font-size: 50px;
    font-family: 'Concert One';
}

.Rhoeas_RhoeasDiv__270ve {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 80vh;
}

@media screen and (max-width: 850px) {
    .Rhoeas_Rhoeas__2E8UR {font-size: 55px;} 
    .Rhoeas_RhoeasDiv__270ve {min-height: 60vh;}
    .Rhoeas_RhoeasSubText__1O1dV {font-size: 30px;}
    
  }
.RhoeasGames_RhoeasGamesDiv__2rZxH {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px;
}

@media screen and (max-width: 800px) {
    .RhoeasGames_RhoeasGamesDiv__2rZxH{ justify-content: space-around;}
  }

.RhoeasGamesHeader_RhoeasGamesHeaderDiv__OeCaI {
    margin-top: 20px;
    font-family:'Oxanium',cursive, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    background-color: rgb(241, 217, 217);
      
}

.RhoeasGamesHeader_RhoeasGamesHeaderText__YXZ3Z {
    margin:1rem; 
    text-align: center; 
    padding: 10px;
}
.MathCheer_cardContainer__he_WC {
    display:flex;
    flex-direction: column;
    width: 500px;
    align-items: center;
    background-color: ivory;
    border-radius: 20px;
}
.MathCheer_privacyPolicy__2H2N- {
    background-color: rgb(123, 187, 230);
    font-family:  'Oxanium', Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    width: 70%;
    border-radius: 15px;
    padding: 10px 0 10px 0;
    margin-bottom: 40px;
    text-align: center;
    font-size: x-large;
    font-weight: bolder;
    text-decoration: none;
}

a {
    text-decoration: none;
    
}

.MathCheer_gameHeader__1HlS3 {
    font-family: 'Balsamiq Sans', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    width: 95%;
    border-radius: 15px;
    text-align: center;
    background-color: rgb(253, 136, 136);
    margin: 40px 0px 20px 0px;
    padding: 10px 0 10px 0;
    }

.MathCheer_subText__2ENWa {
    font-family:  'Balsamiq Sans', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    padding-top: 10px ;
    font-size: 23px;
    text-align: center;
}
img {border-radius: 20px;
    width: 90%;
    height: auto;
    border: 3px solid saddlebrown;
}


@media screen and (max-width: 1000px) {
    .MathCheer_cardContainer__he_WC{ width: 350px;}
  }

@media screen and (max-width: 400px) {
    .MathCheer_cardContainer__he_WC{ width: 80vw;}
  }


.MathCheer_privacyPolicyMainHeader__2VSpK {
    background-color: sandybrown;
    text-align: center;
    padding: 10px;
 
}
.MathCheer_privacyPolicyDiv__1TgJa {
    width: 800px;
    background-color: ivory;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.MathCheer_privacyPolicyDiv__1TgJa h3 {
    text-align: center;
    font-size: larger;
}

.MathCheer_privacyPolicyDiv__1TgJa h4 {
    padding: 20px;
    margin: 0px;
    font-size: larger;
}

.MathCheer_privacyPolicyDiv__1TgJa a{
    padding: 20px;
    font-size: larger;
    -webkit-text-decoration-color: blue;
            text-decoration-color: blue;
    font-weight: bolder;
    text-decoration: underline;
}

.MathCheer_privacyPolicyDiv__1TgJa p {
    text-align: left;
    margin: 0px;
    padding: 0px 20px 0px 20px;
    font-size: large;
}

.MathCheer_privacyPolicyMainDiv__1jvIF {
    display: flex;
    flex-direction: raw;
    justify-content: center;
}
